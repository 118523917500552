import auth from '@/middleware/auth.js'

export default [{
    path: '',
    component: () =>
        import ('@/layouts/Constelacion'),
    children: [
        {
            path: '/cobranzas',
            name: 'cobranzas',
            component: () =>
                import ('@/apps/cobranza_admin/Dashboard'),
            meta: {
                title: 'Dashboard Cobranzas',
                middleware: auth
            }
        },
        {
            path: '/cobranzas/index',
            name: 'cobranzas_index',
            component: () =>
                import ('@/apps/cobranza_admin/pages/cobranzas/Cobranzas'),
            meta: {
                title: 'Cobranzas',
                middleware: auth
            }
        },
        {
            path: '/cobranzas/listas',
            name: 'cobranzas_listas',
            component: () =>
                import ('@/apps/cobranza_admin/pages/cobranzas/Listas'),
            meta: {
                title: 'Listas',
                middleware: auth
            }
        },
        {
            path: '/cobranzas/usuarios',
            name: 'cobranzas_usuarios',
            component: () =>
                import ('@/apps/cobranza_admin/pages/cobranzas/Usuarios'),
            meta: {
                title: 'Usuarios',
                middleware: auth
            }
        }
    ]
}]
